.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
}
.public-DraftStyleDefault-block {
  margin: 1em 0;
}
.rdw-editor-wrapper:focus {
  outline: none;
}
.rdw-editor-wrapper {
  box-sizing: content-box;
}
.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}
.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}