.rdw-image-alignment-options-popup {
  position: absolute;
  background: white;
  display: flex;
  padding: 5px 2px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  width: 105px;
  cursor: pointer;
  z-index: 100;
}
.rdw-alignment-option-left {
  justify-content: flex-start;
}
.rdw-image-alignment-option {
  height: 15px;
  width: 15px;
  min-width: 15px;
}
.rdw-image-alignment {
  position: relative;
}
.rdw-image-imagewrapper {
  position: relative;
}
.rdw-image-center {
  display: flex;
  justify-content: center;
}
.rdw-image-left {
  display: flex;
}
.rdw-image-right {
  display: flex;
  justify-content: flex-end;
}
.rdw-image-alignment-options-popup-right {
  right: 0;
}
