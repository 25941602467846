.rdw-colorpicker-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}
.rdw-colorpicker-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 175px;
  height: 175px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-colorpicker-modal-header {
  display: flex;
  padding-bottom: 5px;
}
.rdw-colorpicker-modal-style-label {
  font-size: 15px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0 10px 5px;
}
.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7;
}
.rdw-colorpicker-modal-options {
  margin: 5px auto;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  overflow: scroll;
}
.rdw-colorpicker-cube {
  width: 22px;
  height: 22px;
  border: 1px solid #F1F1F1;
}
.rdw-colorpicker-option {
  margin: 3px;
  padding: 0;
  min-height: 20px;
  border: none;
  width: 22px;
  height: 22px;
  min-width: 22px;
  box-shadow: 1px 2px 1px #BFBDBD inset;
}
.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #BFBDBD;
}
.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #BFBDBD;
}
.rdw-colorpicker-option-active {
  box-shadow: 0px 0px 2px 2px #BFBDBD;
}
