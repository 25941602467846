.rdw-option-wrapper {
  border: 1px solid #F1F1F1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}
.rdw-option-wrapper:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-option-active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-option-disabled {
  opacity: 0.3;
  cursor: default;
}
