.rdw-fontsize-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-fontsize-dropdown {
  min-width: 40px;
}
.rdw-fontsize-option {
  display: flex;
  justify-content: center;
}
