.rdw-emoji-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}
.rdw-emoji-modal {
  overflow: auto;
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 235px;
  height: 180px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-emoji-icon {
  margin: 2.5px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
