.rdw-fontfamily-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-fontfamily-dropdown {
  width: 115px;
}
.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rdw-fontfamily-optionwrapper {
  width: 140px;
}
